<template>
  <div>
    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col md="5">
              <search
                placeholder="Email, Phone"
                :search-query.sync="searchQuery"
                @refresh="getUserData"
              />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="getUserData" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <ul
            v-if="Object.keys(userData).length !== 0"
            class="list-unstyled"
          >
            <li
              v-if="userData.name"
              class="mb-1"
            >
              <b>Name</b>: {{ userData.name }}
            </li>
            <li
              v-if="userData.email"
              class="mb-1"
            >
              <b>Email</b>: {{ userData.email }}
            </li>
            <li
              v-if="userData.phone"
              class="mb-1"
            >
              <b>Phone</b>: {{ userData.phone }}
            </li>
            <li
              class="mb-1"
            >
              <b>Verification Code</b>: <span v-if="userData.verification_code">{{ userData.verification_code }}</span>
              <p
                v-if="userData.mobile_verified_at"
                class="font-weight-bold"
              >
                Sent at: {{ $moment(userData.mobile_verified_at).format('YYYY-MM-DD HH:mm:ss') }}
              </p>
            </li>
          </ul>

          <div
            v-else
            class="text-center"
          >
            No User found
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'

export default {
  name: 'UserOTP',
  components: {
    Search,
    SearchButton,
  },
  data() {
    return {
      searchQuery: '',
      userData: {},
    }
  },
  methods: {
    getUserData() {
      this.$portalUsers.get('internalops/user-otp', {
        params: {
          key: this.searchQuery,
        },
      }).then(res => {
        this.userData = res.data.data
      }).catch(() => {
        this.userData = {}
      })
    },
  },
}
</script>
<style lang="scss">

</style>
